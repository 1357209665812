import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { AuthGuard } from 'src/auth/guard';
// layouts
import AdminLayout from 'src/layouts/admin';
// components
import { LoadingScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------

// OVERVIEW
const IndexPage = lazy(() => import('src/pages/dashboard/app'));

// Channel
const ChannelDetailsPage = lazy(() => import('src/pages/dashboard/channel/details'));
const ChannelListPage = lazy(() => import('src/pages/dashboard/channel/list'));
const ChannelCreatePage = lazy(() => import('src/pages/dashboard/channel/new'));
const ChannelEditPage = lazy(() => import('src/pages/dashboard/channel/edit'));
// RECORDING
const RecordingListPage = lazy(() => import('src/pages/dashboard/recording/list'));
const RecordingEditPage = lazy(() => import('src/pages/dashboard/recording/edit'));

// REVIEWS
const ReviewListPage = lazy(() => import('src/pages/dashboard/review/list'));
// USER
const UserProfilePage = lazy(() => import('src/pages/dashboard/user/profile'));
const UserCardsPage = lazy(() => import('src/pages/dashboard/user/cards'));
const UserListPage = lazy(() => import('src/pages/dashboard/user/list'));
const UserAccountPage = lazy(() => import('src/pages/dashboard/user/account'));
const UserCreatePage = lazy(() => import('src/pages/dashboard/user/new'));
const UserEditPage = lazy(() => import('src/pages/dashboard/user/edit'));
const UserDetailsPage = lazy(() => import('src/pages/dashboard/user/details'));

// ----------------------------------------------------------------------

export const adminRoutes = [
  {
    path: 'admin',
    element: (
      <AuthGuard>
        <AdminLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </AdminLayout>
      </AuthGuard>
    ),
    children: [
      { element: <IndexPage />, index: true },
      {
        path: 'recordings',
        children: [
          { element: <RecordingListPage />, index: true },
          { path: 'recordings', element: <RecordingListPage /> },
          { path: ':id/edit', element: <RecordingEditPage /> },
        ],
      },
      { path: 'reviews', element: <ReviewListPage /> },
      {
        path: 'user',
        children: [
          { element: <UserProfilePage />, index: true },
          { path: 'profile', element: <UserProfilePage /> },
          { path: 'cards', element: <UserCardsPage /> },
          { path: 'list', element: <UserListPage /> },
          { path: 'new', element: <UserCreatePage /> },
          { path: ':id', element: <UserDetailsPage /> },
          { path: ':id/edit', element: <UserEditPage /> },
          { path: 'account', element: <UserAccountPage /> },
        ],
      },
      {
        path: 'channel',
        children: [
          { element: <ChannelListPage />, index: true },
          { path: 'list', element: <ChannelListPage /> },
          { path: ':id', element: <ChannelDetailsPage /> },
          { path: 'new', element: <ChannelCreatePage /> },
          { path: ':id/edit', element: <ChannelEditPage /> },
        ],
      },
    ],
  },
];
